import { Flex, chakra, Skeleton, Link, Image } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import config from 'configs/app';
import isBrowser from 'lib/isBrowser';


type AdData = {
  content_ads: string;
  image_link: string;
  title: string;
  url_ads: string;
}

const SliseBanner = ({ className }: { className?: string }) => {
  const [ adData, setAdData ] = React.useState<AdData | null>(null);
  const [ isLoading, setIsLoading ] = React.useState(true);

  const baseUrl = config.getEnvValue(process.env.NEXT_PUBLIC_APP_HOST);

  useEffect(() => {
    // if (isBrowser()) {
      fetch(`https://${baseUrl}/api/v2/get_ads?type=all`)
        .then(res => res.status === 200 ? res.json() : null)
        .then((_data) => {
          const data = _data as AdData;
          setAdData(data);
        })
        .finally(() => {
          // setAdData(MOCK);
          setIsLoading(false);
        });
    //}
  }, [ ]);

  if (isLoading) {
    return (
      <Skeleton
        className={ className }
        h={{ base: 12, lg: 6 }}
        w="100%"
        flexGrow={ 1 }
        maxW="800px"
        display="block"
      />
    );
  }

  if (!adData) {
    return null;
  }

  return (
    <>
      <Flex className={ className } h="90px" display={{ base: 'none', lg: 'flex' }}>
        <Link href={ adData.url_ads } target='_blank'><Image src={ adData.image_link } mb="-4px" mr={ 1 } display="inline" alt=""/></Link>        
      </Flex>
      <Flex className={ className } h="90px" display={{ base: 'flex', lg: 'none' }}>
        <Link href={ adData.url_ads } target='_blank'><Image src={ adData.image_link } mb="-4px" mr={ 1 } display="inline" alt=""/></Link>       
      </Flex>
    </>
  );
};

export default chakra(SliseBanner);
